import React from 'react';
import Flex from 'styled-flex-component';

import { useTrackedState } from '../lib/state';

import SubLayout from '../components/SubLayout';
import { P } from '../components/common/P.style';
import { Img } from '../components/common/Img.style';
import { H3, H4 } from '../components/common/H.style';
import Card from '../components/Card';
import ListItem from '../components/ListItem';
import { ContentWrapper } from '../components/common/ContentWrapper.style';
import ButtonList from '../components/ButtonList';
import Anchor from '../components/Anchor';

import ex1lg2 from '../assets/img/ex-1-lg_2.png';
import echopanc from '../assets/img/echo-panc.png';
import ec1sm from '../assets/img/ec-1-sm.png';

const Exams = () => {
  const { size } = useTrackedState();
  return (
    <SubLayout topTitle="当院の検査">
      <Img src={ex1lg2} />
      <ButtonList
        size={size}
        list={[
          { title: '超音波検査', link: '/exams#ultrasound-test' },
          { title: '血液検査', link: '/exams#blood-test' },
        ]}
      />
      <P>
        緊急性の高い疾患にも対応できるよう血算・CRPの迅速検査機器を設置。
        <br />
        １０分あまりで貧血や炎症の程度がチェックできるようになり、重症かどうかの診断がより正確かつ迅速にできます。
      </P>
      <P>糖尿病管理に重要なHbA1cも、10分程度で測定可能。</P>
      <P>
        レントゲンも、デジタル式の高性能なものを使用。
        <br />
        迅速に画像がサーバーに送られ、すぐに診察室で診断ができます。
      </P>
      <P>
        超音波検査装置については大学病院級のものを導入し、女性のベテラン超音波専門技師による腹部エコー、頚動脈エコー、甲状腺エコー、心エコー、乳腺エコーを提供しております。
      </P>
      <P>
        その他にも、２４時間心電図（ホルター心電図）や脈波検査（血管年齢測定、PWV/ABI）、睡眠時無呼吸症候群の簡易検査機器もご用意。
        ２４時間心電図（ホルター心電図）については循環器の専門医にデータを送り、専門的な解析をしてもらっています。
      </P>
      <Anchor id="ultrasound-test">
        <H3>超音波検査</H3>
      </Anchor>
      <P>
        当院での超音波検査には、『ALOKA ARIETTA S70』（日立アロカメディカル社）を使用しております。
      </P>
      <ContentWrapper hasMarginBottom>
        <Flex center={size !== 'sm'} wrap={(size === 'sm' && 'true') || 'false'}>
          <Img
            src={ec1sm}
            css={`
              width: ${size !== 'sm' ? '50%' : '100%'};
            `}
          />
          <Img
            src={echopanc}
            css={`
              width: ${size !== 'sm' ? '50%' : '100%'};
            `}
          />
        </Flex>
      </ContentWrapper>
      <H4>腹部超音波検査を受けられる際の注意</H4>
      <ContentWrapper hasMarginBottom hasMarginTop>
        <Card>
          <Flex column>
            <ListItem
              iconColor="#f0862e"
              label="検査の7時間前までに食事を済ませて下さい。その際消化のよいものをとるようにして下さい。"
            />
            <ListItem
              iconColor="#f0862e"
              label="水やお茶は飲んでいただいて構いませんが、ミルクティーやカフェオレ等の乳製品などは一切飲まないでください。"
            />
            <ListItem
              iconColor="#f0862e"
              label="勝胱等も観察するため、検査の2時間前にトイレに行っていただき、その後お水を多めに飲み尿をためた状態でご来院下さい。目安は20〜30分我慢できる程度です。"
            />
            <ListItem
              iconColor="#f0862e"
              label="検査当日は、腹部、背部を出しやすい服装でいらして下さい。(ワンピースは避けてください)"
            />
            <ListItem
              iconColor="#f0862e"
              label="いつも飲んでいる薬は通常通り飲んでいただいてかまいませんが、糖尿病の薬など、例外があります。詳しくは医師にご確認下さい。"
            />
            <ListItem
              iconColor="#f0862e"
              label="超音波検査は予約制です。「診察」ではなく、「超音波(エコー)検査」の予約をおとりください。"
            />
            <ListItem
              iconColor="#f0862e"
              label="予約時間を過ぎますと検査を受けられない場合がございます。必ず予約時間の5分前にはお越しください。"
            />
          </Flex>
        </Card>
      </ContentWrapper>
      <H4>乳腺超音波検査を受けられる際の注意</H4>
      <ContentWrapper hasMarginBottom hasMarginTop>
        <Card>
          <Flex column>
            <ListItem
              iconColor="#f0862e"
              label="検査中は上半身裸になっていただきますので、着替えやすい服装でお越しください。"
            />
            <ListItem
              iconColor="#f0862e"
              label="妊娠中の方は産科主治医の許可を取ってから受けるようにしてください。"
            />
          </Flex>
        </Card>
      </ContentWrapper>
      <H4>甲状腺・頸動脈超音波検査を受けられる際の注意</H4>
      <ContentWrapper hasMarginBottom hasMarginTop>
        <Card>
          <ListItem
            iconColor="#f0862e"
            label="首にゼリーを塗って検査しますので、首元が広く開く服装でお越しください。（タートルネックは避けてください）"
          />
        </Card>
      </ContentWrapper>
      <Anchor id="blood-test">
        <H3>血液検査</H3>
      </Anchor>
      <P>
        当院で測定できる迅速血液検査（当日結果を出せる血液検査）の項目は以下の通りです。
        <br />
        ただし、迅速検査の性格上、外注する場合に比べて料金が高く、測定可能範囲（下限値、上限値）が狭く、精度もやや劣ることがありますので、基本的には医師の判断で「迅速検査が妥当」と判断した場合のみ施行しておりますのでご了承ください。
      </P>
      <Card>
        <Flex column>
          <ListItem
            iconColor="#f0862e"
            label="白血球（WBC）、赤血球（RBC）、ヘモグロビン（Hb）、ヘマトクリット（Hct）、血小板数（Plt）"
          />
          <ListItem iconColor="#f0862e" label="CRP" />
          <ListItem iconColor="#f0862e" label="肝機能検査　AST（GOT）、ALT（GPT）、γ-GTP" />
          <ListItem iconColor="#f0862e" label="腎機能検査　尿素窒素（BUN）、クレアチニン（Crea）" />
          <ListItem
            iconColor="#f0862e"
            label="脂質検査　総コレステロール（T-cho）、HDLコレステロール、中性脂肪（TG）、（LDLは中性脂肪が400以下の場合のみ、計算で算出）"
          />
          <ListItem iconColor="#f0862e" label="アミラーゼ（AMY）" />
          <ListItem iconColor="#f0862e" label="血糖（GLU）、HbA1c" />
          <ListItem iconColor="#f0862e" label="尿酸値（UA）" />
          <ListItem iconColor="#f0862e" label="PT-INR（主にワーファリン管理に使用）" />
          <ListItem
            iconColor="#f0862e"
            label="ヒト心臓由来脂肪酸結合蛋白（H-FABP）定性（心筋梗塞の診断などに使用）"
          />
        </Flex>
      </Card>
      <P>
        以上、比較的多くの採血項目について迅速検査が可能ですが、これでも日常診療には全く不十分であり、通常の血液検査をカバーできません。
        <br />
        糖尿病治療中の方のHbA1c、ワーファリン服用中のPT-INR測定などのワンポイント測定や、緊急時用の検査ですので、ご了承ください。
      </P>
    </SubLayout>
  );
};

export default Exams;
